$default-color: #555;
$default-shade: #353535;
$default-tint: #aaa;
$grey-1: #979797;
$grey-2: #e5e5e5;
$grey-3: #f9f9f9;
$white: #fff;
$blue: #4a9ae1;
$shadow-color: rgba(0, 0, 0, .2);

$baskerville: 'Libre Baskerville', 'Times New Roman', Times, serif;
$palatino: Palatino, 'Palatino LT STD', 'Palatino Linotype', 'Book Antiqua', 'Georgia', serif;
$sans-font: 'Helvetica Neue', 'Segoe UI', Helvetica, Arial, sans-serif;

$monospaced-font: Menlo, Monaco, monospace;
$code-color: #bf616a;

@mixin box-sizing($type: border-box) {
  -webkit-box-sizing: $type;
     -moz-box-sizing: $type;
          box-sizing: $type;
}

@mixin transition($args...) {
  -webkit-transition: $args;
     -moz-transition: $args;
          transition: $args;
}
