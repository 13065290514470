* {
  @include box-sizing;
  line-height: 1.5;
}

html,
body {
  color: $default-color;
  margin: 0;
  padding: 0;
}

html {
  font-family: $baskerville;
  font-size: 14px;

  @media (min-width: 600px) {
    font-size: 16px;
  }
}

body {
  -webkit-text-size-adjust: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $default-shade;
  font-family: $sans-font;
  line-height: normal;
}

a {
  color: $blue;
  text-decoration: none;
}

blockquote {
  border-left: .25rem solid $grey-2;
  color: $grey-1;
  margin: .8rem 0;
  padding: .5rem 1rem;

  p:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 600px) {
    padding: 0 5rem 0 1.25rem;
  }
}

img {
  display: block;
  margin: 0 0 1rem;
  max-width: 100%;
}

td {
  vertical-align: top;
}

.contact ul li {
  display: inline-block;
  margin-left: 10px;
  margin-right: 25px;
}

.contact .contact-title {
  position: relative;
  color: #333030;
  font-weight: 400;
  font-size: 12px;
  margin: 0 0 5px;
  text-transform: uppercase;
  text-align: center;
}

i {
  color: #353535;
  margin-right: 35px;
}

.contact ul li a:hover {
  color: #333030;
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

.contact ul li a {
  color: #515151;
  display: block;
  padding: 5px;
  font-size: 18px;
  -webkit-transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);
  -o-transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82);
  transition: all 350ms cubic-bezier(0.13, 0.43, 0.54, 1.82) 0ms;
}

.contact-links {
  width: 70%;
  margin: auto;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
  margin: 15px
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img {
  border-radius: 5px 5px 0 0;
}

.container {
  padding: 2px 16px;
}

.card-container {
  flex-direction: row;
  display: flex;
}

.line-center{
  margin:0;padding:0 10px;
  background:#fff;
  display:inline-block;
}
.about-separator{

  text-align:center;
  position:relative;
  z-index:2;

}
.about-separator:after{
  content:"";
  position:absolute;
  top:50%;
  left:0;
  right:0;
  border-top:solid 1px red;
  z-index:-1;
}

.about p {
  text-align: center !important;
}

.disable-max-width {
  max-width: inherit !important;
}

